import React from 'react';
import './CryptoPage.css';
import Owl_image from '../img/owl_image.png';
import CryptoContent from './CryptoContent';

const CryptoPage = () => {
  return (

    <div className="crypto-page">

      <div className='crypto-header_line' id="home-section"></div>

        <header className="crypto-header">
          meet<span> $owl</span>
        </header>


        <div className="crypto-image">
              <img src={Owl_image} alt="Owl image"/>
        </div>

      <CryptoContent />

    </div>
  );
};

export default CryptoPage;
