import React from 'react';
import owl_about_image from "../img/owl_about_image.png"
import './About.css';

const About = () => {
  return (



<div className='st'>

        <h6 class="blanc" id="about-section">TEST</h6>
        <div className="sliding-text-container" >
        <span className="sliding-text" >someone hoots <span>in the hoods?</span></span>
        </div>

      <svg className='back_star1' width="75" height="75" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14.5 7.5C10.7528 7.0153 8.80399 6.43599 8 0C7.40119 5.04107 5.77625 6.7893 0 7.5C4.58974 7.70647 6.64055 8.79309 8 15C7.67691 10.6315 8.64887 8.76145 14.5 7.5Z" fill="white"/>
      </svg>

    <div className="about">
        <div className='about_container'>
            <div className="about_content">
                <div className="about_text">
                    <p>IT'S $OWL, THE HOOTIEST OF COINS! BORN AS A TRIBUTE TO ELI BEN SASSON – THE STARKWARE WIZARD – 
                        $OWL IS THE BEAK-ON-THE-BLOCKCHAIN IN HIS NEST. NOT JUST A TOKEN, BUT A WHOLE NEW FLAP IN THE CRYPTO UNIVERSE! <br /><br />
                    NOT JUST A TOKEN, BUT A WHOLE NEW FLAP IN THE CRYPTO UNIVERSE! $OWL'S NOT ABOUT RUFFLING FEATHERS; 
                    IT'S ABOUT SOARING THROUGH THE STARKWARE SKIES, MAKING THE BLOCKCHAIN WORLD A HOOT FOR ALL. <br /><br />
                    WHAT'S $OWL'S GAME? SIMPLE: IT'S BRINGING THE CRYPTO PERCH TO EVERY BRANCH, WHETHER YOU'RE A WIDE-EYED FLEDGLING OR AN OLD-HAT HAWK. 
                    GET READY TO SPREAD YOUR WINGS WITH $OWL – IT'S A HOOT <br /><br /> </p>
                    <button className='about_button'>Learn more</button>
                </div>
                <div className="about_image">
                    <img src={owl_about_image} alt="Owl" />
                </div>
            </div>            
        </div>    

    </div>


    <svg className='back_star2' width="75" height="75" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.5 7.5C10.7528 7.0153 8.80399 6.43599 8 0C7.40119 5.04107 5.77625 6.7893 0 7.5C4.58974 7.70647 6.64055 8.79309 8 15C7.67691 10.6315 8.64887 8.76145 14.5 7.5Z" fill="white"/>
    </svg>








</div>


  );
};

export default About;
