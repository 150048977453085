import React, { useEffect, useState } from 'react';
import './Navbar.css';

const Navbar = () => {

    const redirectUrl = (url) => {
        window.open(url, '_blank');
    };

    const scrollToElement = (elementId) => {
        if (elementId === 'home-section') {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        } else {
            const element = document.getElementById(elementId);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }
    };


    return (
        <nav className="navbar" >

            <div className='navbar_icons'>

                <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11" fill="none">
                    <path d="M7.2099 3.06345C7.21194 3.06345 7.21466 3.06345 7.21738 3.06345C7.2884 3.06345 7.35432 3.08554 7.40835 3.1236L7.40733 3.12292C7.44675 3.15724 7.47326 3.2055 7.47972 3.25987V3.26089C7.48651 3.30234 7.49025 3.34992 7.49025 3.39851C7.49025 3.4206 7.48957 3.44235 7.48787 3.4641V3.46104C7.41141 4.26607 7.07975 6.21832 6.9112 7.11951C6.83984 7.50113 6.6995 7.6289 6.56357 7.64113C6.26827 7.66866 6.04399 7.44607 5.75786 7.2585C5.30998 6.96455 5.05682 6.78173 4.62219 6.49527C4.1196 6.16462 4.44549 5.98214 4.73162 5.68514C4.80671 5.60698 6.10855 4.42272 6.13404 4.31534C6.1354 4.30888 6.13608 4.30175 6.13608 4.29427C6.13608 4.26776 6.12622 4.24364 6.11025 4.22529C6.09258 4.21373 6.07049 4.20728 6.04739 4.20728C6.0321 4.20728 6.01748 4.21034 6.00389 4.21543L6.00457 4.21509C5.95971 4.22529 5.24429 4.69809 3.85828 5.63349C3.70707 5.75277 3.51609 5.8282 3.30812 5.83704H3.30608C3.01146 5.80136 2.74402 5.73577 2.49018 5.64232L2.51533 5.65048C2.19658 5.5465 1.94341 5.49179 1.96516 5.31576C1.97672 5.22424 2.1029 5.13056 2.34372 5.03473C3.82713 4.3884 4.81634 3.96238 5.31134 3.75668C5.85743 3.46682 6.49051 3.22826 7.15621 3.07365L7.20956 3.06311L7.2099 3.06345ZM5.10575 0C2.28459 0.00679634 0 2.29547 0 5.11765C0 7.94391 2.29105 10.2353 5.11765 10.2353C7.94425 10.2353 10.2353 7.94425 10.2353 5.11765C10.2353 2.29547 7.9507 0.00679634 5.13022 0H5.12954C5.12161 0 5.11368 0 5.10575 0Z" fill="url(#paint0_linear_101_117)" onClick={() => redirectUrl('https://t.me/StarkOwl')}
                    style={{ cursor: 'pointer' }} />
                    <defs>
                    <linearGradient id="paint0_linear_101_117" x1="5.11765" y1="0" x2="5.11765" y2="10.2353" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#00B2FF"/>
                    <stop offset="1" stop-color="#015C95"/>
                    </linearGradient>
                    </defs>
                </svg>


                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="11" viewBox="0 0 12 11" fill="none">
                    <path d="M12 1.21163C11.5607 1.41714 11.0885 1.55604 10.5929 1.61851C11.0988 1.2987 11.4872 0.792323 11.6701 0.188971C11.1968 0.48505 10.6725 0.700095 10.1144 0.816002C9.66751 0.313815 9.03082 0 8.3262 0C6.97318 0 5.87625 1.15691 5.87625 2.58386C5.87625 2.78638 5.89795 2.98359 5.93971 3.1727C3.90361 3.06495 2.09843 2.03625 0.890121 0.47298C0.679243 0.854607 0.558429 1.29847 0.558429 1.77201C0.558429 2.66844 0.990974 3.45934 1.64833 3.92269C1.24671 3.90928 0.868981 3.79305 0.538643 3.59952C0.538468 3.6103 0.538468 3.62113 0.538468 3.632C0.538468 4.88394 1.38294 5.92831 2.50368 6.16565C2.29809 6.22471 2.08166 6.25626 1.8582 6.25626C1.70035 6.25626 1.54687 6.24009 1.39731 6.20992C1.70904 7.2365 2.61379 7.98354 3.68583 8.00441C2.84738 8.69741 1.79103 9.1105 0.643252 9.1105C0.445521 9.1105 0.250498 9.09829 0.0588379 9.07443C1.14302 9.80755 2.43078 10.2353 3.81429 10.2353C8.32048 10.2353 10.7846 6.298 10.7846 2.88349C10.7846 2.77145 10.7823 2.66001 10.7775 2.54922C11.2562 2.18491 11.6716 1.72981 12 1.21163Z" fill="url(#paint0_linear_101_118)" onClick={() => redirectUrl('https://twitter.com/OwlStarknet')}
                    style={{ cursor: 'pointer' }} />
                    <defs>
                    <linearGradient id="paint0_linear_101_118" x1="6.02943" y1="0" x2="6.02943" y2="10.2353" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#00B2FF"/>
                    <stop offset="1" stop-color="#015C95"/>
                    </linearGradient>
                    </defs>
                </svg>

            </div>


            <div className='navlinks'>
                
                <li  onClick={() => scrollToElement('home-section')}>Home</li>

                <li  onClick={() => scrollToElement('about-section')}>About us</li>

                <li  onClick={() => scrollToElement('tokenomics-section')}>Tokenomics</li>

                <li  onClick={() => scrollToElement('faq-section')}>Faqs</li>

                <li  onClick={() => scrollToElement('faqs-section')}>Faqs</li>

            </div>


            <div className='navbar_buttons'>

                <button className='enter_daap' onClick={() => redirectUrl('https://zapper.starknet-owl.xyz/')}style={{ cursor: 'pointer' }}>Enter dapp</button>


                <button className='buy_avnu' onClick={() => redirectUrl('https://app.avnu.fi/en?tokenFrom=0x49d36570d4e46f48e99674bd3fcc84644ddd6b96f7c741b1562b82f9e004dc7&tokenTo=0x039877a272619050ab8b0e3e0a19b58d076fc2ce84da1dc73b699590e629f2b8&amount=0.1')}style={{ cursor: 'pointer' }}>Buy on AVNU</button>


            </div>


        </nav>
    );
};


export default Navbar;
