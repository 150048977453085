import React, { useState } from 'react';
import './Faqs.css';

const faqsData = [
  {
    id: 0o1,
    question: 'HOW CAN I BRIDGE TO STARKNET?',
    answer: 'Firstly, you must have an EVM wallet filled with Ethereum. Secondly, you need a starknet wallet, we recommend Argent X. Once you have both wallets set up, go to www.orbiter.finance and connect both your EVM wallet & Argent X wallet. Now select Ethereum to Starknet. You will then be able to bridge across seamlessly. This also works going the opposite way from Starknet to Ethereum or many other layer 2s.'
  },

  {
    id: 0o2,
    question: 'IS THERE A TAX ON THIS TOKEN ?',
    answer: 'No, there is no tax. We initially launched with a 5% sell tax, which all of it was sent to the Jedi LP. We later removed the sales tax to 0 and renounced the contract. Tax will forever be 0/0'
  },

  {
    id: 0o3,
    question: 'HOW DO I BUY $OWL ?',
    answer: 'You can use either www.jediswap.xyz or app.avnu.fi'
  },

  {
    id: 0o4,
    question: 'WHAT IS THE CONTRACT ADDRESS ?',
    answer: '0x039877a272619050ab8b0e3e0a19b58d076f c2ce84da1dc73b699590e629f2b8'
  },
 
  {
    id: 0o5,
    question: 'WHAT IS TOTAL SUPPLY ?',
    answer: 'Total supply is 100,000,000 token, however we have burnt 23% of supply.'
  }

  
];

function Faqs() {
  const [activeId, setActiveId] = useState(null);

  const toggleFAQ = (id) => {
    setActiveId(activeId === id ? null : id);
  };

  return (

<div className='faq' >


    <svg className="back_star4" xmlns="http://www.w3.org/2000/svg" width="986" height="206" version="1.1"id="faq-section">
    <polyline points="939.0,60.5 938.5,60.0 938.5,59.0 938.5,58.0 938.0,57.5 937.5,57.0 937.5,56.0 937.0,55.5 936.5,55.0 936.5,54.0 936.0,53.5 935.5,53.0 935.5,52.0 935.0,51.5 934.5,51.0 934.0,50.5 933.5,50.0 933.0,49.5 932.0,49.5 931.5,49.0 931.0,48.5 930.0,48.5 929.0,48.5 928.5,48.0 928.0,47.5 927.0,47.5 926.0,47.5 925.5,47.0 926.0,46.5 927.0,46.5 928.0,46.5 929.0,46.5 929.5,46.0 930.0,45.5 931.0,45.5 932.0,45.5 932.5,45.0 933.0,44.5 934.0,44.5 934.5,44.0 935.0,43.5 935.5,43.0 936.0,42.5 936.5,42.0 936.5,41.0 937.0,40.5 937.5,40.0 937.5,39.0 938.0,38.5 938.5,38.0 938.5,37.0 938.5,36.0 938.5,35.0 939.0,34.5 939.5,35.0 940.0,35.5 940.5,36.0 940.5,37.0 940.5,38.0 940.5,39.0 941.0,39.5 941.5,40.0 941.5,41.0 942.0,41.5 942.5,42.0 942.5,43.0 943.0,43.5 943.5,44.0 944.0,44.5 944.5,45.0 945.0,45.5 946.0,45.5 946.5,46.0 947.0,46.5 948.0,46.5 949.0,46.5 950.0,46.5 950.5,47.0 950.5,48.0 950.0,48.5 949.0,48.5 948.0,48.5 947.5,49.0 947.0,49.5 946.0,49.5 945.0,49.5 944.5,50.0 944.0,50.5 943.5,51.0 943.0,51.5 942.5,52.0 942.0,52.5 941.5,53.0 941.0,53.5 940.5,54.0 940.5,55.0 940.0,55.5 939.5,56.0 939.5,57.0 939.5,58.0 939.5,59.0 939.5,60.0 939.0,60.5" fill="#FFFFFF" stroke="black"/>
    <polyline points="963.0,81.5 962.5,81.0 962.0,80.5 961.0,80.5 960.5,80.0 960.5,79.0 960.0,78.5 959.5,78.0 960.0,77.5 960.5,77.0 960.5,76.0 961.0,75.5 962.0,75.5 962.5,75.0 963.0,74.5 963.5,75.0 964.0,75.5 965.0,75.5 965.5,76.0 965.5,77.0 966.0,77.5 966.5,78.0 966.0,78.5 965.5,79.0 965.5,80.0 965.0,80.5 964.0,80.5 963.5,81.0 963.0,81.5" fill="#FFFFFF" stroke="black"/>
    <polyline points="55.0,105.5 54.0,105.5 53.0,105.5 52.5,105.0 52.5,104.0 52.5,103.0 52.5,102.0 53.0,101.5 54.0,101.5 55.0,101.5 55.5,102.0 56.0,102.5 56.5,103.0 56.5,104.0 56.0,104.5 55.5,105.0 55.0,105.5" fill="#FFFFFF" stroke="black"/>
    <polyline points="22.0,156.5 21.0,156.5 20.0,156.5 19.0,156.5 18.5,156.0 18.0,155.5 17.5,155.0 17.5,154.0 17.5,153.0 17.5,152.0 18.0,151.5 18.5,151.0 19.0,150.5 20.0,150.5 21.0,150.5 21.5,151.0 22.0,151.5 22.5,152.0 23.0,152.5 23.5,153.0 23.5,154.0 23.5,155.0 23.0,155.5 22.5,156.0 22.0,156.5" fill="#FFFFFF" stroke="black"/>
    <polyline points="50.0,195.5 49.0,195.5 48.5,195.0 48.5,194.0 48.0,193.5 47.5,193.0 47.5,192.0 47.5,191.0 47.0,190.5 46.5,190.0 46.0,189.5 45.5,189.0 45.0,188.5 44.5,188.0 44.0,187.5 43.5,187.0 43.0,186.5 42.5,186.0 42.0,185.5 41.0,185.5 40.0,185.5 39.5,185.0 39.0,184.5 38.0,184.5 37.0,184.5 36.5,184.0 37.0,183.5 38.0,183.5 39.0,183.5 40.0,183.5 40.5,183.0 41.0,182.5 42.0,182.5 42.5,182.0 43.0,181.5 44.0,181.5 44.5,181.0 45.0,180.5 45.5,180.0 46.0,179.5 46.5,179.0 47.0,178.5 47.5,178.0 47.5,177.0 48.0,176.5 48.5,176.0 48.5,175.0 49.0,174.5 49.5,174.0 49.5,173.0 49.5,172.0 49.5,171.0 50.0,170.5 50.5,171.0 50.5,172.0 50.5,173.0 50.5,174.0 51.0,174.5 51.5,175.0 51.5,176.0 51.5,177.0 52.0,177.5 52.5,178.0 52.5,179.0 53.0,179.5 53.5,180.0 54.0,180.5 54.5,181.0 55.0,181.5 55.5,182.0 56.0,182.5 57.0,182.5 57.5,183.0 58.0,183.5 59.0,183.5 60.0,183.5 61.0,183.5 61.5,184.0 61.0,184.5 60.5,185.0 60.0,185.5 59.0,185.5 58.0,185.5 57.5,186.0 57.0,186.5 56.0,186.5 55.5,187.0 55.0,187.5 54.0,187.5 53.5,188.0 53.0,188.5 52.5,189.0 52.0,189.5 51.5,190.0 51.5,191.0 51.0,191.5 50.5,192.0 50.5,193.0 50.5,194.0 50.5,195.0 50.0,195.5" fill="#FFFFFF" stroke="black"/>
  </svg>

      <div className="faqs" >

      <h2>FAQS</h2>
      <h5 id="faqs-section">FAQS</h5>


      {faqsData.map((faq) => (

          <div key={faq.id} className={`faq-item ${activeId === faq.id ? 'active' : ''}`}>

              <div className="faq-question" onClick={() => toggleFAQ(faq.id)} >

              <span className="faq-diode" />

              <span className="faq-id">{`00${faq.id.toString(8)}`}</span>
              {faq.question}
              </div>
              {activeId === faq.id && <div className="faq-answer">{faq.answer}</div>}
          </div>
          
      ))}
      </div>
      
</div>
  );
}

export default Faqs;
