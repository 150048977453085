import React, { useState, useEffect } from 'react';
import './CryptoContent.css';

const CryptoContent = () => {

    const [data, setData] = useState([
    { title: "TOTAL HOLDERS", value: "Loading..." },
    { title: "MARKETCAP", value: "Loading..." },
    { title: "OWL PRICE", value: "Loading..." }
  ]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://api.coingecko.com/api/v3/coins/stark-owl');
        const json = await response.json();

        const totalHolders = '5000';

        const marketCap = `$${(json.market_data.fully_diluted_valuation.usd / 1000).toFixed(1)}K`;
        const owlPrice = `$${json.market_data.current_price.usd.toFixed(4)}`;

        setData([
          { title: "TOTAL HOLDERS", value: totalHolders },
          { title: "MARKETCAP", value: marketCap },
          { title: "OWL PRICE", value: owlPrice }
        ]);
      } catch (error) {
        console.error('Error fetching data: ', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="crypto-content">

      {data.map((item, index) => (

        <div className="stat-box" key={index}>

          <div className="stat-diode"></div>

          <div className="stat-content-wrapper"> 

            <div className="stat-content">
              <span className="stat-title">{item.title}</span>
              <span className="stat-value">{item.value}</span>
            </div>

          </div>

        </div>

      ))}

    </div>
  );
};

export default CryptoContent;
