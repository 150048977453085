import React from 'react';
import Navbar from './components/Navbar/Navbar.js';
import FirstPart from './components/CryptoPage/CryptoPage.js';
import About from './components/About/About.js';
import Tokenomics from './components/Tokenomics/Tokenomics.js';
import Faqs from './components/Faqs/Faqs.js';
import ApePart from './components/ApePart/ApePart.js'
import Footer from './components/Footer/Footer.js';
import './App.css';


function App() {
  return (
    <div>

    <Navbar />

    <FirstPart />

    <About />

    <Tokenomics />

    <Faqs />
  
    <svg className="back_star5" xmlns="http://www.w3.org/2000/svg" width="83" height="63" version="1.1">
      <polyline points="61.0,24.5 60.0,24.5 59.5,24.0 59.0,23.5 58.5,23.0 59.0,22.5 59.5,22.0 60.0,21.5 61.0,21.5 61.5,22.0 62.0,22.5 62.5,23.0 62.0,23.5 61.5,24.0 61.0,24.5" fill="#FFFFFF" stroke="black"/>
      <polyline points="27.0,43.5 26.5,43.0 26.5,42.0 26.5,41.0 26.0,40.5 25.5,40.0 25.5,39.0 25.0,38.5 24.5,38.0 24.0,37.5 23.5,37.0 23.0,36.5 22.5,36.0 22.0,35.5 21.0,35.5 20.5,35.0 20.0,34.5 19.0,34.5 18.0,34.5 17.0,34.5 16.5,34.0 17.0,33.5 18.0,33.5 19.0,33.5 19.5,33.0 20.0,32.5 21.0,32.5 21.5,32.0 22.0,31.5 23.0,31.5 23.5,31.0 24.0,30.5 24.5,30.0 25.0,29.5 25.5,29.0 26.0,28.5 26.5,28.0 26.5,27.0 26.5,26.0 27.0,25.5 27.5,25.0 27.5,24.0 28.0,23.5 28.5,24.0 28.5,25.0 28.5,26.0 28.5,27.0 29.0,27.5 29.5,28.0 29.5,29.0 30.0,29.5 30.5,30.0 30.5,31.0 31.0,31.5 32.0,31.5 32.5,32.0 33.0,32.5 33.5,33.0 34.0,33.5 35.0,33.5 36.0,33.5 37.0,33.5 37.5,34.0 37.0,34.5 36.0,34.5 35.0,34.5 34.5,35.0 34.0,35.5 33.0,35.5 32.5,36.0 32.0,36.5 31.0,36.5 30.5,37.0 30.0,37.5 29.5,38.0 29.0,38.5 28.5,39.0 28.5,40.0 28.0,40.5 27.5,41.0 27.5,42.0 27.5,43.0 27.0,43.5" fill="#FFFFFF" stroke="black"/>
    </svg>


    <ApePart />

    <Footer />








    </div>
  );
}

export default App;
