import React from 'react';
import './Tokenomics.css';

const Tokenomics = () => {
  const sections = [
    { color: '#00B2FF', label: 'LIQUIDITY', value: 48 },
    { color: '#00E0FF', label: 'BURN POOL', value: 23 },
    { color: '#0653B5', label: 'TEAM', value: 11 },
    { color: '#01447E', label: 'INITIAL LIQUIDITY PROVIDER', value: 10 },
    { color: '#F19100', label: 'MARKETING', value: 5 },
    { color: '#FFFFFF', label: ' COMMUNITY INCENTIVES', value: 3 }
  ];

  return (
    <div className="tokenomics-container" id="tokenomics-section">

      <h2><span>TOKEN</span>OMICS</h2>

      <svg className="back_star3" width="50" height="50" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.5 7.5C10.7528 7.0153 8.80399 6.43599 8 0C7.40119 5.04107 5.77625 6.7893 0 7.5C4.58974 7.70647 6.64055 8.79309 8 15C7.67691 10.6315 8.64887 8.76145 14.5 7.5Z" fill="white"/>
        </svg>

        <div className="tokenomics-content"  >

            <div className="values-list">

            {sections.map((section, index) => (
            <div key={index} className="value-item">
                <span className="dot" style={{ background: section.color }}></span>
                <span className="percentage">{section.value}%</span>
                <span className={`label ${index === 4 || index === 5 ? 'label-adjustment' : ''}`}>{section.label}</span>
            </div>
          ))}
            </div>

            <div className="chart">

                <svg width="500" height="500" viewBox="0 0 165 165" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M153.106 39.8265C155.934 44.5054 158.29 49.4538 160.139 54.5988L82.5 82.4999L153.106 39.8265Z" fill="white"/>
                    <path d="M159.996 54.2054C167.122 73.7211 166.621 95.2066 158.596 114.37C150.57 133.533 135.61 148.962 116.704 157.575L82.5 82.4999L159.996 54.2054Z" fill="#9EE0FD"/>
                    <path d="M77.1557 0.173283C98.1841 -1.1918 118.938 5.5416 135.159 18.9922L82.5 82.4999L77.1557 0.173283Z" fill="#00375B"/>
                    <path d="M2.32826 63.0391C6.87156 44.3223 17.8203 27.7849 33.2768 16.2933C48.7334 4.80171 67.7235 -0.919812 86.9557 0.12041L82.5 82.4999L2.32826 63.0391Z" fill="#01619F"/>
                    <path d="M119.461 156.257C100.668 165.674 78.9903 167.563 58.8525 161.538C38.7148 155.513 21.6362 142.029 11.104 123.838C0.571671 105.647 -2.61999 84.1223 2.18063 63.6581C6.98126 43.1939 19.4121 25.3338 36.9351 13.7245L82.5001 82.5L119.461 156.257Z" fill="#00B2FF"/>
                    <path d="M134.965 18.8314C142.179 24.7758 148.33 31.9028 153.155 39.9082L82.5 82.4999L134.965 18.8314Z" fill="#F19100"/>
                    <ellipse cx="82.5" cy="81.0398" rx="48.9159" ry="48.9159" fill="#001027"/>
                </svg>

            </div>
        </div>
    </div>
  );
};

export default Tokenomics;
