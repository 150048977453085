import React, { useState } from 'react';
import './Footer.css';

const Footer = () => {


  const redirectUrl = (url) => {
    window.open(url, '_blank');
};

const scrollToElement = (elementId) => {
    if (elementId === 'home-section') {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
        const element = document.getElementById(elementId);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }
};

  return (
    <footer className="footer">
      <div className="footer-section">
        <h4>We're $OWL</h4>
        <ul>
          <li onClick={() => scrollToElement('about-section')}>A short story</li>
          <li onClick={() => scrollToElement('tokenomics-section')}>Tokenomics</li>
          <li onClick={() => scrollToElement('faq-section')}>FAQ</li>
          <li onClick={() => scrollToElement('faqs-section')}>FAQ</li>
        </ul>
      </div>
      <div className="footer-section">
        <h4>Socials</h4>
        <ul>
          <li><a onClick={() => redirectUrl('https://twitter.com/OwlStarknet')}style={{ cursor: 'pointer' }}>Twitter</a></li>
          <li><a onClick={() => redirectUrl('https://t.me/OwlStarknet')} style={{ cursor: 'pointer' }}>Telegram</a></li>
        </ul>
      </div>

      <div className="footer-bottom">

        <p>© 2023 Owl <br /> All Rights Reserved.</p>
        
      </div>
    {/* Made by Florian ISAK */}
    </footer>
  );
};

export default Footer;
                

